import { Container, Header, Button, Alert } from '@amzn/awsui-components-react-v3';
import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { consolidateResources, signInWithCurrentProvider } from '@/modules';
import { useUserInfo } from '@/utils';

import messages from './ResourceConsolidationPage.message';
import { hiddenAlertBannerItem, getFormattedIdpName } from './ResourceConsolidationPage.utils';

import './ResourceConsolidationPage.scss';

const ResourceConsolidationPage = () => {
    const { formatMessage } = useIntl();
    const [consolidationProcessing, setConsolidationProcessing] = useState(false);
    const { email, email_verified, public_provider_name } = useUserInfo();

    useEffect(() => {
        if (email_verified === false) {
            signInWithCurrentProvider();
        }
    }, [email_verified]);

    const idpNameMessage = getFormattedIdpName(public_provider_name, formatMessage);
    const [alertBannerItem, setAlertBannerItem] = useState(hiddenAlertBannerItem);

    const errorAlertBannerItem = {
        type: 'error',
        content: formatMessage(messages.consolidationError),
        dismissible: true,
        dismissAriaLabel: formatMessage(messages.consolidationErrorDismiss),
        onDismiss: () => setAlertBannerItem(hiddenAlertBannerItem),
    };

    const handleResetResources = async () => {
        setConsolidationProcessing(true);
        setAlertBannerItem(hiddenAlertBannerItem);
        let payload;
        try {
            payload = await consolidateResources();
        } catch (err) {
            console.log('Failed to reset resources', err);
        } finally {
            setConsolidationProcessing(false);
        }

        if (payload && payload.statusCode === 200) {
            await signInWithCurrentProvider(false);
        } else {
            setAlertBannerItem(errorAlertBannerItem);
        }
    };

    return (
        <div>
            <Helmet>
                <title>{formatMessage(messages.pageTitle)}</title>
            </Helmet>
            <Alert {...alertBannerItem} data-testid='resourceConsolidation-error-alert'>
                {alertBannerItem?.content}
            </Alert>
            <div className='container-wrapper'>
                <Container header={<Header variant='h2'>{formatMessage(messages.title)}</Header>}>
                    {formatMessage(messages.mainMessage)}
                    <ul>
                        <li>
                            {formatMessage(messages.bulletPointOne, {
                                signInMethod: idpNameMessage,
                                userEmail: email,
                                bold: (str) => <b>{str}</b>,
                            })}
                        </li>
                        <li>{formatMessage(messages.bulletPointTwo)}</li>
                    </ul>
                    <div className='button-wrapper'>
                        <Button
                            fullWidth
                            loading={consolidationProcessing}
                            variant='primary'
                            onClick={handleResetResources}
                            data-testid='button-consolidation'
                        >
                            {formatMessage(messages.buttonContinueConsolidation, {
                                signInMethod: idpNameMessage,
                            })}
                        </Button>
                    </div>
                    <div className='button-wrapper'>
                        <Button
                            fullWidth
                            disabled={consolidationProcessing}
                            onClick={async () => await Auth.signOut()}
                            data-testid='button-change-signin'
                        >
                            {formatMessage(messages.buttonChooseOtherSignInMethod)}
                        </Button>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default ResourceConsolidationPage;
