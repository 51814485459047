import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { acceptInvitations } from '@/graphql/mutations';
import { executeRequest } from '@/modules/api';

const acceptAllInvitations = async (stateSetter, errorSetter, collection = []) => {
    const acceptedInvitations = await executeRequest({ operation: acceptInvitations })
        .then((response) => response?.acceptInvitations?.acceptedInvitations ?? [])
        .catch((err) => {
            errorSetter(err);
            return [];
        });

    if (acceptedInvitations.length) {
        acceptAllInvitations(stateSetter, errorSetter, [...collection, ...acceptedInvitations]);
    }
    stateSetter(collection);
};

export const useAcceptInvitations = () => {
    const [acceptedInvitations, acceptedInvitationsSet] = useState(null);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        acceptAllInvitations(acceptedInvitationsSet, showBoundary);
    }, [showBoundary]);

    return acceptedInvitations;
};
