import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    pageTitle: {
        id: 'ResourceConsolidationPage.pageTitle',
        description: 'ResourceConsolidation page meta title',
        defaultMessage: 'Confirm sign-in preference - AWS Training and Certification',
    },
    title: {
        id: 'ResourceConsolidationPage.title',
        description: 'ResourceConsolidation page title',
        defaultMessage: 'Please confirm your preferred sign-in method',
    },
    mainMessage: {
        id: 'ResourceConsolidationPage.mainParagraph',
        description: 'ResourceConsolidation page main message',
        defaultMessage:
            'We are streamlining our authorization policies. As a result, all customers must confirm the sign-in method they prefer to use when creating and managing classes.',
    },
    bulletPointOne: {
        id: 'ResourceConsolidationPage.bulletPointOne',
        description: 'ResourceConsolidation page bullet point 1',
        defaultMessage:
            "If <bold>{signInMethod}</bold> is your preferred sign-in method for <bold>{userEmail}</bold>, simply continue to Builder Labs for classes. You won't be prompted again.",
    },
    bulletPointTwo: {
        id: 'ResourceConsolidationPage.bulletPointTwo',
        description: 'ResourceConsolidation page bullet point 2',
        defaultMessage:
            'If you prefer to use a different sign-in method with this email, return to sign in and choose your preferred sign-in method.',
    },
    buttonContinueConsolidation: {
        id: 'ResourceConsolidationPage.buttonContinueWithCurrentSignInMethod',
        description: 'ResourceConsolidation page button to continue consolidation',
        defaultMessage: 'Continue with {signInMethod}',
    },
    buttonChooseOtherSignInMethod: {
        id: 'ResourceConsolidationPage.buttonChooseOtherSignInMethod',
        description: 'ResourceConsolidation page button to log out and sign in with other methods',
        defaultMessage: 'Choose a different sign-in method',
    },
    signInAmazonEmployeeSSO: {
        id: 'ResourceConsolidationPage.signInMethod.AmazonEmployeeSSO',
        description: 'message for Amazon Employee SSO sign-in method',
        defaultMessage: 'Amazon Employee Single Sign-on',
    },
    signInAWSBuilderID: {
        id: 'ResourceConsolidationPage.signInMethod.AWSBuilderID',
        description: 'message for AWS Builder ID sign-in method',
        defaultMessage: 'AWS Builder ID',
    },
    signInOTP: {
        id: 'ResourceConsolidationPage.signInMethod.OTP',
        description: 'message for OTP sign-in method',
        defaultMessage: 'One-time Email Passcode',
    },
    signInLWA: {
        id: 'ResourceConsolidationPage.signInMethod.loginWithAmazon',
        description: 'message for Login with Amazon sign-in method',
        defaultMessage: 'Login with Amazon',
    },
    signInAPN: {
        id: 'ResourceConsolidationPage.signInMethod.AWSPartnerNetwork',
        description: 'message for AWS Partner Network sign-in method',
        defaultMessage: 'AWS Partner Network',
    },
    signInOrgSSO: {
        id: 'ResourceConsolidationPage.signInMethod.organizationSSO',
        description: 'message for Organization SSO sign-in method',
        defaultMessage: 'Organization SSO',
    },
    consolidationError: {
        id: 'ResourceConsolidationPage.consolidationError',
        description: 'message for the error banner for failed consolidation',
        defaultMessage:
            "The preferred sign-in method couldn't be set due to resource consolidation failure. Please try again.",
    },
    consolidationErrorDismiss: {
        id: 'ResourceConsolidationPage.consolidationErrorDismiss',
        description: 'message for dismiss the error banner for failed consolidation',
        defaultMessage: 'Dismiss',
    },
});

export default messages;
